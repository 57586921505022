import styled from "styled-components"
import { GlobalContext, GlobalProvider } from "../utils/global"
import { useContext, useEffect } from "react"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import gsap from 'gsap'
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import ScrollButton from "./ScrollButton"

const AppContainer = styled.div`
  position: relative;
`

function App({ children }) {
  const { device } = useContext(GlobalContext)

  i18next.use(LanguageDetector).init({
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'en',
    resources: {
      fr: {
        translation: {
          "nav-home" : "Accueil",
          "nav-about" : "A propos",
          "nav-project" : "Notre technique",
          "nav-contact" : "Contact",
          "nav-blog": "Blog",
          "hero-title" : "Optimisez votre site web.",
          "hero-catchphrase" : "On ne promet pas la lune, juste un site qui décolle.",
          "hero-description" : "Attirez plus de client grâce à un site web puissant et bien référencé.",
          "hero-description-two" : "Notre agence digitale crée votre site internet ou Ecommerce pour qu'il soit agréable, moderne et en première page de Google afin d'être bien référencé.",
          "hero-cta-more" : "Nos offres",
          "hero-cta-contact" : "Nous contacter",
          "customer-section-title" : "Ils nous font confiance",
          "customer-review-section-title" : "Ils nous font confiance",
          "project-section-title" : "Nos plateformes",

          "start-project-section-title" : "Comment bien démarrer son projet\u00A0?",
          "first-step-title" : "Votre besoin est notre priorité",
          "first-step-description" : "Créez de nouveaux appels d'offres et conservez vos clients existants. La performance des dispositifs élaborés est la seule vérité au sein de notre agence web ! Notre agence assure le référencement naturel ou payant, de fidélisation et de conversion.",
          "second-step-title" : "Amorcez votre projet avec une approche solide",
          "second-step-description" : "Nous créons votre site vitrine ou votre e-boutique en ligne avec les meilleures technologies afin d'être responsive design et optimisé pour le référencement naturel, afin que vous soyez toujours à la page.",
          "last-step-title" : "À votre écoute",
          "last-step-description" : "Est-ce que vous avez besoin d'un devis ou d'un accompagnement pour créer un site internet ou une boutique en ligne pour vendre sur Internet ? Est-ce que vous souhaitez réaliser un projet web sur mesure ? Vous cherchez une agence de communication spécialisée dans le référencement naturel d'un site ou un conseil en communication ? Communiquez avec notre agence web digitale.",
          
          "about-section-title" : "À propos",
          "about-section-second-title-history" : "Nos valeurs",
          "about-section-history-description" : "Du site web sur-mesure au CMS puissant, nos solutions optimisées sont approuvées par nos clients et collaborateurs. Notre philosophie est d’acquérir les meilleures méthodes actuelles, la veille technologique demeure un postulat fondamental. Ce concept nous permet d'offrir des services à la pointe de l'innovation, garantissant ainsi la satisfaction.",
          "about-section-second-title-skill" : "Tarifs et compétences",
          
          "skill-one-title" : "Site Internet",
          "skill-one-rate" : "À partir de 1350€",
          "skill-one-description": "Nous avons pour objectif de créer des sites web, vitrine ou Ecommerce. Nous nous occupons de tous les projets web.",
          "skill-one-list-one": "Utilisation d'un CMS (Wordpress, Joomla, Shopify).",
          "skill-one-list-two": "Site vitrine ou e-commerce.",
          "skill-one-list-three": "Optimisation SEO.",
          "skill-one-list-four": "Hébergement sécurisé.",
          "skill-one-list-five": "1 Validation et 1 Modification.",

          "skill-two-title" : "Conception et réalisation de maquette",
          "skill-two-rate" : "À partir de 400€",
          "skill-two-description": "Nous offrons un service unique qui se concentre sur l'inventivité, la créativité, la force de propositions et le partage d'idées. Notre engagement est total jusqu'à ce que notre mission soit accomplie.",
          "skill-two-list-one": "Création d'un projet Figma",
          "skill-two-list-two": "Intégrations des médias professionnels.",
          "skill-two-list-three": "1 Validation et 1 modification.",

          "skill-three-title" : "Maintenance et mise à jour",
          "skill-three-rate" : "À partir de 850€",
          "skill-three-description": "Les équipes et l'expertise de Web-boost sont là pour vous accompagner dans le renouvellement de votre projet numérique. Nous sommes une agence web digitale qui offre tous les services nécessaires pour la refonte de votre projet.",
          "skill-three-list-one": "Audit SEO.",
          "skill-three-list-two": "Analyse de l'expérience utilisateur.",
          "skill-three-list-three": "Mise à jour et intégration des modifications.",
          "skill-three-list-four": "1 Validation et 1 modification.",

          "skill-four-title" : "Conformités RGPD",
          "skill-four-rate" : "À partir de 1250€",
          "skill-four-description": "Notre engagement envers la protection de vos données personnelles est au cœur de notre service. Vous optez pour une gestion éthique et responsable, soutenue par des pratiques conformes aux plus hauts standards. Chaque projet est minutieusement étudié.",
          "skill-four-list-one": "Audit RGPD.",
          "skill-four-list-two": "Rédactions des confidentialités.",
          "skill-four-list-three": "Conseils et mise à jour sur l'écosystème web.",

          "skill-five-title" : "Référencement naturel SEO",
          "skill-five-rate" : "À partir de 1150€",
          "skill-five-description": "Pour mener une stratégie de référencement naturel, il est important de se concentrer sur la qualité du contenu et de la sémantique, du code HTML, mais également la bonne intégration des liens externes et internes dans les pages.",
          "skill-five-list-one": "Analyse de l'écosystème web actuel.",
          "skill-five-list-two": "Intégrations des données SEO (médias, codes natifs, texte).",
          "skill-five-list-three": "Installations d'outils d'analyse de performance (KPI).",

          "skill-six-title" : "Formation",
          "skill-six-rate" : "À partir de 50€/h",
          "skill-six-description": "Propulser la présence en ligne consiste à analyser régulièrement les processus de conception, de développement et de maintenance des sites internet afin d'identifier les axes d'amélioration. Cette démarche vise à optimiser les méthodes utilisées, à accroître l'efficacité et à garantir une qualité optimale.",
          "skill-six-list-one": "Transmettre une technique, un savoir-faire.",
          "skill-six-list-two": "Établir  la  programmation  des activités pédagogiques.",
          "skill-six-list-three": "Établir une évaluation de diagnostic et sommative.",
          "skill-six-list-four": "Suivi individuel et collectif.",

          "techno-section-title" : "Notre technique",
          "planet-method" : "Méthode Agile",
          "planet-marketing": "Influence marketing",

          "question-section-title" : "Foire aux questions",
          "question-catchphrase" : "Des questions avant de prendre des décisions ? Nous y répondons ici !",
          "question-one" : "Découvrez les étapes déjà franchies de votre projet",
          "question-one-answer" : "Pour optimiser le suivi de votre projet, nous vous recommandons d'utiliser des outils collaboratifs tels que Discord ou Microsoft Teams. Ces plateformes permettent une communication en temps réel, facilitant ainsi les échanges entre les membres de votre équipe.",
          "question-two": "Participez à la gestion collaborative de votre projet",
          "question-two-answer" : "Nous vous encourageons à utiliser Google Drive, ce service de stockage en ligne qui permet de centraliser tous vos documents de projet. Il offre une multitude de fonctionnalités qui le rendent idéal pour gérer des projets efficacement. Vous pouvez stocker, partager et collaborer sur des fichiers en temps réel.",
          "question-three" : "Quels sont les avantages de créer un site internet\u00A0?",
          "question-three-answer" : "Augmenter sa visibilité, sa clientèle, la qualité de son service après-vente mais également faire face à la concurrence. Ces stratégies reposent en grande partie sur l'identité web, Web-Boost vous offre cette opportunité.",
          "question-four" : "Dois-je créer un site vitrine\u00A0?",
          "question-four-answer" : "C’est en quelque sorte votre carte de visite sur le web. Sa fonction principale est de présenter votre entreprise, vos produits ou services, et de mettre à disposition les informations inhérentes à votre boutique (horaires, directions, contact, etc.).",
          "question-five" : "Devrais-je créer un e-commerce\u00A0?",
          "question-five-answer" : "Avec un e-commerce, vous pouvez bénéficier de tous les avantages d’un site vitrine, tout en permettant à vos clients d’effectuer leurs achats en ligne. Si vous souhaitez numériser votre commerce, c’est donc l’option idéale.",
          "question-six" : "Forum, blog ou site complet\u00A0?",
          "question-six-answer" : "Le forum est approprié si vous avez une communauté qui désire échanger, et si vous voulez rassembler des gens sur un lieu virtuel. Le blog, quant à lui, sert à présenter votre contenu, utile si vous avez des gens qui vous suivent ou si vous êtes un influenceur. Le site complet est parfait si vous souhaitez rassembler plusieurs des sites cités sur cette page en un seul site, par exemple : forum et e-commerce.",
          "question-seven" : "Mise à jour\u00A0? Pourquoi\u00A0?",
          "question-seven-answer" : "Mettre à jour son site web, c'est augmenter le nombre d'informations, le nombre de pages et le contenu de son site internet. La maintenance et l'actualisation régulières du site web sont des facteurs importants pour l'optimisation du référencement d'un site.",
          
          "contact-section-codeur" : "Plateforme Codeur",
          "contact-section-malt" : "Plateforme Malt",
          "contact-section-bark" : "Plateforme Bark",
          "contact-section-network" : "Suivez nous sur nos réseaux sociaux",
          "footer-legal-info" : "Informations légales",
          "footer-policy" : "Politique de confidentialité",
          "footer-legal" : "Mentions légales",
          "footer-cgv": "CGV",
          "modal-title" : "Votre message a été envoyé !",
          "modal-text": "Nous vous remercions pour votre message. Nous allons traiter votre demande dans les meilleurs délais.",
          "contact-page-form-title": "Contactez-nous",
          "contact-page-form-input-name": "Nom",
          "contact-page-form-input-company": "Société",
          "contact-page-form-input-email": "Email",
          "contact-page-form-input-phone": "Télephone",
          "contact-page-form-input-message": "Message",
          "contact-page-form-button-send": "Envoyer"
        }
      },
      en: {
        translation: {
          "nav-home" : "Home",
          "nav-about" : "About",
          "nav-project" : "Our technique",
          "nav-contact" : "Contact",
          "nav-blog": "Blog",
          "hero-title": "Optimize your website.",
          "hero-catchphrase": "We're not promising the moon, just a site that takes off.",
          "hero-description" : "Attract more customers with a powerful, well-ranked website.",
          "hero-description-two" : "Our digital agency creates your website or Ecommerce so that it is pleasant, modern and on the first page of Google in order to be well referenced.",
          "hero-cta-more" : "Our offers",
          "hero-cta-contact" : "Contact us",
          "customer-section-title" : "They trust us",
          "customer-review-section-title" : "They trust us",
          "project-section-title" : "Our platforms",

          "start-project-section-title" : "How to get your project off to a good start ?",
          "first-step-title" : "Your needs are our priority",
          "first-step-description" : "Create new tenders and retain your existing customers. The performance of the devices developed is the only truth within our web agency! Our agency provides natural or paid referencing, loyalty and conversion.",
          "second-step-title" : "Start your project with a solid approach",
          "second-step-description" : "We create your showcase site or your online e-store with the best technologies in order to be responsive design and optimized for natural referencing, so that you are always up to date.",
          "last-step-title" : "At your service",
          "last-step-description" : "Do you need a quote or support to create a website or an online store to sell on the Internet? Do you want to create a tailor-made web project? Are you looking for a communications agency specializing in the natural referencing of a site or communications consultancy? Contact our digital web agency.",
          
          "about-section-title" : "About us",
          "about-section-second-title-history" : "Our values",
          "about-section-historic-description" : "From custom-made websites to powerful CMS, our optimized solutions are approved by our customers and collaborators. Our philosophy is to acquire the best current methods, technological monitoring remains a fundamental postulate. This concept allows us to offer services at the forefront of innovation, thus guaranteeing satisfaction.",
          "about-section-second-title-skill" : "Rates and skills",
         
          "skill-one-title" : "Website",
          "skill-one-rate" : "From 1350€",
          "skill-one-description": "Our goal is to create websites, showcase or Ecommerce. We take care of all web projects.",
          "skill-one-list-one": "Use of a CMS (Wordpress, Joomla, Shopify).",
          "skill-one-list-two": "Showcase or e-commerce site.",
          "skill-one-list-three": "SEO optimization.",
          "skill-one-list-four": "Secure hosting.",
          "skill-one-list-five": "1 Validation and 1 Modification.",

          "skill-two-title" : "Design and creation of models",
          "skill-two-rate" : "From 400€",
          "skill-two-description": "We offer a unique service that focuses on inventiveness, creativity, the strength of proposals and the sharing of ideas. Our commitment is total until our mission is accomplished.",
          "skill-two-list-one": "Creating a Figma Project.",
          "skill-two-list-two": "Professional media integrations.",
          "skill-two-list-three": "1 Validation and 1 Modification.",

          "skill-three-title" : "Maintenance and updates",
          "skill-three-rate" : "From 850€",
          "skill-three-description": "Web-boost's teams and expertise are there to support you in renewing your digital project. We are a digital web agency that offers all the services necessary to redesign your project.",
          "skill-three-list-one": "Audit SEO.",
          "skill-three-list-two": "Analyse de l'expérience utilisateur.",
          "skill-three-list-three": "Mise à jour et intégration des modifications.",
          "skill-three-list-four": "1 Validation and 1 Modification.",

          "skill-four-title" : "GDPR compliance",
          "skill-four-rate" : "From 1250€",
          "skill-four-description": "Our commitment to protecting your personal data is at the heart of our service. You opt for ethical and responsible management, supported by practices that comply with the highest standards. Each project is carefully studied.",
          "skill-four-list-one": "GDPR audit.",
          "skill-four-list-two": "Drafting of confidentiality agreements.",
          "skill-four-list-three": "Tips and updates on the web ecosystem.",

          "skill-five-title" : "SEO natural referencing",
          "skill-five-rate" : "From 1150€",
          "skill-five-description": "To carry out a natural referencing strategy, it is important to focus on the quality of the content and semantics, of the HTML code, but also the good integration of external and internal links in the pages.",
          "skill-five-list-one": "Analysis of the current web ecosystem.",
          "skill-five-list-two": "SEO data integrations (media, native codes, text).",
          "skill-five-list-three": "Installation of performance analysis tools (KPI).",

          "skill-six-title" : "Training",
          "skill-six-rate" : "From 50€/h",
          "skill-six-description": "Boosting online presence involves regularly analyzing website design, development, and maintenance processes to identify areas for improvement. This approach aims to optimize the methods used, increase efficiency, and ensure optimal qualityh nyhj.",
          "skill-six-list-one": "Pass on a technique, a know-how.",
          "skill-six-list-two": "Establish the programming of educational activities.",
          "skill-six-list-three": "Establish a diagnostic and summative assessment.",
          "skill-six-list-four": "Individual and collective monitoring.",

          "techno-section-title" : "Our technique",
          "planet-method" : "Agile method",
          "planet-marketing": "Marketing influence",
          
          "question-section-title" : "Frequently asked questions",
          "question-catchphrase" : "Any questions before making decisions ? We'll answer them here!",
          "question-one" : "Discover the steps already taken in your project",
          "question-one-answer" : "To optimize the monitoring of your project, we recommend using collaborative tools such as Discord or Microsoft Teams. These platforms allow real-time communication, thus facilitating exchanges between the members of your team.",
          "question-two" : "Participate in the collaborative management of your project",
          "question-two-answer" : "We encourage you to use Google Drive, this online storage service that allows you to centralize all your project documents. It offers a multitude of features that make it ideal for managing projects efficiently. You can store, share and collaborate on files in real time.",
          "question-three" : "What are the advantages of creating a website\u00A0?",
          "question-three-answer" : "Increase your visibility, your clientele, the quality of your after-sales service and also face up to the competition. These strategies are largely based on web identity, and Web-Boost offers you this opportunity.",
          "question-four" : "Do I need to create a showcase site\u00A0?",
          "question-four-answer" : "In a way, it's your business card on the web. Its main function is to present your company, products or services, and to provide information about your shop (opening hours, directions, contact details, etc.).",
          "question-five" : "Should I set up an e-commerce\u00A0?",
          "question-five-answer" : "With an e-commerce site, you can enjoy all the benefits of a shop window site, while allowing your customers to make their purchases online. So if you're looking to digitise your business, this is the ideal option.",
          "question-six" : "Forum, blog or complete site\u00A0?",
          "question-six-answer" : "The forum is appropriate if you have a community that wants to exchange ideas, and if you want to bring people together in a virtual place. The blog, on the other hand, is used to present your content, which is useful if you have people following you or if you are an influencer. The full site is perfect if you want to combine several of the sites listed on this page into a single site, for example: forum and e-commerce.",
          "question-seven" : "Update\u00A0? Why update\u00A0?",
          "question-seven-answer" : "Updating your website means increasing the amount of information, the number of pages and the content of your website. Regular website maintenance and updating are important factors in optimising a site's referencing.",
          
          "contact-section-codeur" : "Plateform Codeur",
          "contact-section-malt" : "Plateform Malt",
          "contact-section-bark" : "Plateform Bark",
          "contact-section-network" : "Follow us on our social networks",
          "footer-legal-info" : "Legal information",
          "footer-policy" : "Privacy policy",
          "footer-legal" : "Legal notice",
          "footer-cgv": "CGV",
          "modal-title" : "Your message has been sent !",
          "modal-text": "We thank you for your message. We will process your request as soon as possible.",
          "contact-page-form-title": "Contact us",
          "contact-page-form-input-name": "Name",
          "contact-page-form-input-company": "Company",
          "contact-page-form-input-email": "Email",
          "contact-page-form-input-phone": "Phone",
          "contact-page-form-input-message": "Message",
          "contact-page-form-button-send": "Send"
        }
      }
    }
  })

  // i18next.changeLanguage('en')
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(MotionPathPlugin)
  gsap.registerPlugin(useGSAP);

  useEffect(() => {
    setTimeout(() => {
      const href = window.location.href
      if (href.includes("#/#")) {
        const id = href.split('#').pop()
        const anchor = document.getElementById(id)
        const y = anchor.getBoundingClientRect().top - 250
        if(anchor){
            window.scrollTo({ top: y, behavior: "smooth" })
        }
      }
    }, 500)
  }, [])
  
  return (
    <GlobalProvider>
      <AppContainer>
        { children }
        <ScrollButton />
      </AppContainer>
    </GlobalProvider>
  )
}

export default App
