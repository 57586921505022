import styled from "styled-components"
import logo from "../assets/logo-web-boost-white.png"
import { ReactComponent as Whatsapp } from "../assets/whatsapp-128-svgrepo-com.svg"
import i18next from "i18next"
import { HashLink } from "react-router-hash-link"
import { forwardRef } from "react"

const NavigationContainer = styled.div`
    background-color: #130633;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 100;
`

const WebBoostContainer = styled(HashLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
`

const WebBoostLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
`

const Image = styled.img`
    width: 100%;
`

const WebBoostTitle = styled.h2`
    color: white;
    padding: 0 0 0 1rem;
    font-size: 2.4rem;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        font-size: 1.8rem;
    }
`

const Nav = styled.nav`
    display: flex;
    align-items: center;
`

const StyledLink = styled(HashLink)`
    color: white;
    margin-left: 2rem;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 300;

    svg {
        width: 40px;
        height: 40px;
        filter: brightness(0) saturate(100%) invert(98%) sepia(12%) saturate(238%) hue-rotate(297deg) brightness(118%) contrast(100%);
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        margin-left: 1.1rem;
        font-size: 1.1rem;

        svg {
            width: 35px;
            height: 35px;
        }
    }
`

const Navigation = forwardRef(function Navigation(props, ref) {

    return (
        <NavigationContainer ref={ref}>
            <WebBoostContainer to="/">
                <WebBoostLogoContainer>
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>
                <WebBoostTitle>Web Boost</WebBoostTitle>
            </WebBoostContainer>

            <Nav>
                <StyledLink smooth to="/#header_section">{i18next.t("nav-home")}</StyledLink>
                <StyledLink smooth to="/#about_section">{i18next.t("nav-about")}</StyledLink>
                <StyledLink smooth to="/#technologies-title">{i18next.t("nav-project")}</StyledLink>
                <StyledLink smooth to="/blog">{i18next.t("nav-blog")}</StyledLink>
                <StyledLink to="/contact">{i18next.t("nav-contact")}</StyledLink>
                <StyledLink to="https://wa.me/+33786524195" target="_blank">
                    <Whatsapp />
                </StyledLink>
            </Nav>
        </NavigationContainer>
    )
})

export default Navigation