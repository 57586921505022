import { HashLink } from "react-router-hash-link"
import styled from "styled-components"

const RedButton = styled.button`
    border: none;
    background-color: #E42A3E;
    border-radius: 38px;
    padding: 1.4rem 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin: 0 1rem;
    font-size: 1.5rem;
    z-index: 1;
    position: absolute;
    display: inline-block;
    max-width: 260px;
    border: 2px solid #E42A3E;
    cursor: pointer;
    
    &.about {
        position: relative;
        margin: 0 auto;
        display: block;
    }

    &:hover {
        background-color: white;
        transition: background-color 500ms, color 500ms;

        & a {
            color: #E42A3E;
        }
    }

    @media only screen and (max-width : 1024px) {
        margin: 1rem;
    }

    @media only screen and (max-width : 767px) {
        font-size: 1.2rem;
    }
`

const StyledLink = styled(HashLink)`
    text-decoration: none;
    color: white;
`

function HeaderButton({label, className, link}) {
    return (
        <RedButton className={className}>
            <StyledLink smooth to={link}>{label}</StyledLink>
        </RedButton>
    )
}

export default HeaderButton