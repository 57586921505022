import styled from "styled-components"
import Footer from "../components/Footer"
import logo from "../assets/logo-web-boost-white.png"
import { Header, WebBoostLogoContainer, Image, Title, SubTitle, Paragraph, List, WebBoostLink } from "../utils/Atoms"

const Container = styled.div`

`

const ItemList =styled.li`

`

function CGVPage() {
    return (
        <Container>
            <Header>
                <WebBoostLogoContainer to="/">
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>

                <WebBoostLink to="/">
                    <Title className="header">Web boost</Title>
                </WebBoostLink>
            </Header>

            <Title className="title">Conditions Générales de Vente</Title>

            <SubTitle>Préambule</SubTitle>
            <Paragraph className="end">
                Les présentes Conditions Générales de Vente (ci-après les « CGV ») s'appliquent à toutes les ventes de sites web conclues sur le site internet https://web-boost.fr/ (ci-après le « Site ») de Web boost (ci-après le « Vendeur »).
            </Paragraph>

            <SubTitle>1. Objet</SubTitle>
            <Paragraph className="end">
                Le Vendeur propose sur le Site des sites web pré-conçus ou sur-mesure (ci-après les « Produits ») aux utilisateurs du Site (ci-après les « Clients »).
            </Paragraph>

            <SubTitle>2. Commande</SubTitle>
            <Paragraph className="end">
                La passation d'une commande sur le Site implique l'acceptation pleine et entière des présentes CGV par le Client. Le Client reconnaît avoir pris connaissance des présentes CGV avant de passer sa commande.
            </Paragraph>

            <SubTitle>3. Prix et modalités de paiement</SubTitle>
            <Paragraph>Les prix des Produits sont indiqués sur le Site en euros (€) et incluent la TVA. Les prix s'entendent hors frais de livraison, qui sont facturés en supplément.</Paragraph>
            <Paragraph>Le Client peut régler sa commande par les moyens de paiement suivants :</Paragraph>
            <List>
                <ItemList>Carte bancaire</ItemList>
                <ItemList>PayPal</ItemList>
                <ItemList>Virement bancaire</ItemList>
            </List>

            <SubTitle>4. Livraison</SubTitle>
            <Paragraph className="end">
                Les Produits sont livrés par voie électronique à l'adresse e-mail du Client indiquée lors de la commande. Le délai de livraison est généralement de 24 heures à 7 jours ouvrables.
            </Paragraph>

            <SubTitle>5. Droit de rétractation</SubTitle>
            <Paragraph>
                Le Client dispose d'un délai de rétractation de 14 jours à compter de la date de réception du Produit. Pour exercer son droit de rétractation, le Client doit adresser au Vendeur un courrier électronique à l'adresse contact@web-boost.fr indiquant clairement sa volonté de se rétracter.
            </Paragraph>
            <Paragraph className="end">
                En cas de rétractation du Client, le Vendeur lui remboursera le montant total de la commande, y compris les frais de livraison, dans un délai de 14 jours à compter de la réception du courrier électronique de rétractation du Client.
            </Paragraph>

            <SubTitle>6. Garantie</SubTitle>
            {/* <Paragraph>
                Le Vendeur garantit les Produits contre tout défaut de fabrication pendant une période de [durée de la garantie] à compter de la date de livraison.
            </Paragraph> */}
            <Paragraph className="end">
                En cas de défaut de fabrication, le Client peut demander au Vendeur la réparation ou le remplacement du Produit. Le Vendeur prend en charge les frais de retour et de renvoi du Produit.
            </Paragraph>

            <SubTitle>7. Propriété intellectuelle</SubTitle>
            <Paragraph className="end">
                Le Vendeur conserve la propriété intellectuelle des Produits. Le Client ne peut utiliser les Produits que pour son usage personnel et ne peut pas les reproduire, les distribuer ou les communiquer à des tiers sans l'autorisation écrite du Vendeur.
            </Paragraph>

            <SubTitle>8. Responsabilité</SubTitle>
            <Paragraph>
                Le Vendeur est responsable des dommages directs causés au Client en cas de non-respect des présentes CGV.
            </Paragraph>
            <Paragraph className="end">
                La responsabilité du Vendeur est limitée aux dommages prévisibles au moment de la vente. Le Vendeur ne peut être tenu responsable des dommages indirects ou consécutifs, tels que les pertes de profits ou d'exploitation.
            </Paragraph>

            <SubTitle>9. Règlement des litiges</SubTitle>
            <Paragraph className="end">Les présentes CGV sont soumises au droit français.</Paragraph>
            {/* <Paragraph className="end">En cas de litige, les parties s'efforceront de le résoudre à l'amiable. En cas d'échec, le litige sera porté devant les tribunaux compétents de [ville].</Paragraph> */}
            
            <SubTitle>10. Modifications des CGV</SubTitle>
            <Paragraph className="end">
                Le Vendeur peut modifier les présentes CGV à tout moment. Les modifications des CGV seront applicables aux commandes passées après la date de publication des nouvelles CGV sur le Site.
            </Paragraph>

            <SubTitle>11. Informations de contact</SubTitle>
            <Paragraph>
                Pour toute question ou réclamation, le Client peut contacter le Vendeur à l'adresse contact@web-boost.fr.
            </Paragraph>
            <Footer />
        </Container>
    )
}

export default CGVPage