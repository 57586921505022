import { Link } from "react-router-dom"
import styled from "styled-components"
import  { ReactComponent as DownloadSvg }  from "../assets/download.svg"

const StyledLink = styled.a`
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    background-color: #E42A3E;
    padding: 1rem 2rem;
    border-radius: 35px;
    font-weight: 600;
    transition: all 400ms linear;
    border: #E42A3E 3px solid;
    width: fit-content;

    &:hover {
        background-color: white;
        color: #E42A3E;

        svg {
            fill: #E42A3E;
        }
    }

    svg {
        width: 2rem;
        height: 2rem;
        fill: white;
        padding-right: 1rem;
    }
`

function DownloadButton ({ file, filename, label }) {
    return (
        <StyledLink href={file} download={filename} >
            <DownloadSvg />
            { label }
        </StyledLink>
    )
}

export default DownloadButton