import styled from "styled-components"
import Footer from "../components/Footer"
import logo from "../assets/logo-web-boost-white.png"
import { Header, WebBoostLogoContainer, Image, Title, SubTitle, Paragraph, List, WebBoostLink } from "../utils/Atoms"

const Container = styled.div`

`

const ListItem = styled.li`

`

const Strong = styled.strong`

`
function LegalNoticePage() {
    return (
        <Container>
            <Header>
                <WebBoostLogoContainer to="/">
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>

                <WebBoostLink to="/">
                    <Title className="header">Web boost</Title>
                </WebBoostLink>
            </Header>

            <Title className="title">Mentions Legales</Title>

            <SubTitle>Identification du site</SubTitle>
            <List>
                <ListItem>
                    <Strong>Nom du site : </Strong>
                    Web boost
                </ListItem>
                <ListItem>
                    <Strong>URL du site : </Strong>
                    https://web-boost.fr
                </ListItem>
            </List>

            <SubTitle>Identification de l'éditeur</SubTitle>
            <List>
                <ListItem>
                    <Strong>Nom de l'éditeur : </Strong>
                    Web boost
                </ListItem>
                <ListItem>
                    <Strong>Forme juridique:</Strong>
                    Entrepreneur individuel
                </ListItem>
                <ListItem>
                    <Strong>Siège social : </Strong>
                    57 Rue de la ville - 67460 SOUFFELWEYERSHEIM
                </ListItem>
                <ListItem>
                    <Strong>Numéro de RCS : </Strong>
                    RCS B Souffelweyersheim 814866265
                </ListItem>
                <ListItem>
                    <Strong>Numéro de TVA : </Strong>
                    FR54814866265
                </ListItem>
                <ListItem>
                    <Strong>Directeur de la publication : </Strong>
                    Julien Maingault
                </ListItem>
                <ListItem>
                    <Strong>Contact : </Strong>
                    contact@web-boost.fr
                </ListItem>
            </List>

            {/* <SubTitle>Hébergement du site</SubTitle>
            <List>
                <ListItem>
                    <Strong>Nom de l'hébergeur : </Strong>
                </ListItem>
                <ListItem>
                    <Strong>Adresse de l'hébergeur : </Strong>
                </ListItem>
            </List> */}

            <SubTitle>Conditions générales de vente</SubTitle>
            <Paragraph className="end">
                Les conditions générales de vente du site sont accessibles à l'adresse suivante : https://web-boost.fr/cgv
            </Paragraph>

            <SubTitle>Propriété intellectuelle</SubTitle>
            <Paragraph className="end">
                Le contenu du site est protégé par les droits d'auteur et les droits de propriété intellectuelle. Toute reproduction, représentation, modification ou diffusion sans l'autorisation préalable du propriétaire est interdite.
            </Paragraph>

            <SubTitle>Liens hypertextes</SubTitle>
            <Paragraph className="end">
                La création de liens hypertextes vers le site est libre et gratuite. Cependant, le Vendeur se réserve le droit de faire supprimer un lien hypertexte vers son site si celui-ci est jugé contraire aux lois en vigueur ou aux bonnes mœurs.
            </Paragraph>

            {/* <SubTitle>Loi applicable et juridiction</SubTitle>
            <Paragraph>
                Les présentes mentions légales sont soumises au droit français. Tout litige relatif à ces mentions légales sera porté devant les tribunaux compétents de [ville].
            </Paragraph> */}
            <Footer />
        </Container>
    )
}

export default LegalNoticePage