import styled from "styled-components"
import logo from "../assets/logo-S-removebg-preview.png"
import menu from "../assets/burger-simple-svgrepo-com.svg"
import { HashLink } from "react-router-hash-link"
import { ReactComponent as Whatsapp } from "../assets/whatsapp-128-svgrepo-com.svg"
import { useState } from "react"
import i18next from "i18next"

const NavigationContainer = styled.div`
    background-color: #130633;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
`
const WebBoostContainer = styled.div`
    display: flex;
    align-items: center;
`

const WebBoostLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
`

const Image = styled.img`
    width: 100%;
`

const WebBoostTitle = styled.h2`
    color: white;
    font-size: 1.3rem;
    padding: 0 0 0 0.3rem;
`

const Nav = styled.nav`
    display: flex;
    align-items: center;
`

const NavContent = styled.div`
    position: absolute;
    top: 78px;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #130633;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: max-height 350ms ease-in-out;

    &.isVisible {
        max-height: 16rem;
    }
`

const NavContainer = styled.div`
    display: flex;
`

const ButtonNavigation = styled.button`
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
`

const StyledLink = styled(HashLink)`
    color: white;
    margin-left: 0;
    font-size: 1rem;
    text-decoration: none;
    font-weight: 300;

    svg {
        width: 35px;
        height: 35px;
        filter: brightness(0) saturate(100%) invert(98%) sepia(12%) saturate(238%) hue-rotate(297deg) brightness(118%) contrast(100%);
    }
    &.nav {
        padding: 0.8rem;
        text-align: center;
    }
    &.whatsapp {
        margin-left: 1rem;
    }
`
function MobileNavigation () {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <NavigationContainer>
            <WebBoostContainer>
                <WebBoostLogoContainer>
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>
                <WebBoostTitle>Web Boost</WebBoostTitle>
            </WebBoostContainer>
            <NavContainer>
                <Nav>
                    <ButtonNavigation onClick={() => setIsVisible(!isVisible)}>
                        <Image src={menu} alt="logo de menu burger" className="menu"/>
                    </ButtonNavigation>

                    <NavContent className={isVisible ? "isVisible" : ""}>
                        <StyledLink className="nav" to="/#header_section" onClick={() => setIsVisible(!isVisible)}>{i18next.t("nav-home")}</StyledLink>
                        <StyledLink className="nav" to="/#about_section" onClick={() => setIsVisible(!isVisible)}>{i18next.t("nav-about")}</StyledLink>
                        <StyledLink className="nav" to="/#technologies-title" onClick={() => setIsVisible(!isVisible)}>{i18next.t("nav-project")}</StyledLink>
                        <StyledLink className="nav" to="/blog" onClick={() => setIsVisible(!isVisible)}>{i18next.t("nav-blog")}</StyledLink>
                        <StyledLink className="nav" to="/contact" onClick={() => setIsVisible(!isVisible)}>{i18next.t("nav-contact")}</StyledLink>
                    </NavContent>
                </Nav>

                <StyledLink className="whatsapp" to="https://wa.me/+33786524195" target="_blank">
                    <Whatsapp />
                </StyledLink>
            </NavContainer>
        </NavigationContainer>
    )
}

export default MobileNavigation