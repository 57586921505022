import styled from "styled-components"
import Footer from "../components/Footer"
import logo from "../assets/logo-web-boost-white.png"
import { Header, WebBoostLogoContainer, Image, Title, SubTitle, Paragraph, List, WebBoostLink } from "../utils/Atoms"

const Container = styled.div`

`

const ListItem = styled.li`

`

const Strong = styled.strong`

`

function PrivacyPolicyPage() {
    return (
        <Container>
            <Header>
                <WebBoostLogoContainer to="/">
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>

                <WebBoostLink to="/">
                    <Title className="header">Web boost</Title>
                </WebBoostLink>
            </Header>

            <Title className="title">Politique de confidentialité</Title>

            <SubTitle>1. Introduction</SubTitle>
            <Paragraph>
                Web Boost s'engage à respecter la vie privée des utilisateurs de son site internet https://web-boost.fr. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous visitez notre site ou utilisez nos services.
            </Paragraph>

            <SubTitle>2. Collecte de données</SubTitle>
            <Paragraph>
                Comme nous n'utilisons pas de cookies, nous collectons uniquement les données personnelles que vous nous fournissez volontairement via les formulaires de notre site internet. Ces données peuvent inclure :
            </Paragraph>
            <List>
                <ListItem>
                    <Strong>Informations de contact : </Strong>
                    Nom, adresse e-mail, numéro de téléphone.
                </ListItem>
                <ListItem>
                    <Strong>Finalité : </Strong>
                    Informations que vous nous fournissez dans des formulaires, comme des demandes de devis ou des inscriptions à la newsletter. Plus d’informations sur : https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000045538574
                </ListItem>
            </List>

            <SubTitle>3. Utilisation des données</SubTitle>
            <Paragraph>Nous utilisons vos données personnelles pour les finalités suivantes :</Paragraph>
            <List>
                <ListItem>
                    <Strong>Fournir nos services : </Strong>
                    Traiter vos demandes, répondre à vos questions et vous fournir les services demandés.
                </ListItem>
                <ListItem>
                    <Strong>Envoyer des communications marketing : </Strong>
                    Vous informer sur nos produits, services, promotions et actualités (avec votre consentement).
                </ListItem>
                <ListItem>
                    Respecter nos obligations légales.
                </ListItem>
            </List>
            <Paragraph>Plus d’informations sur : https://www.cnil.fr/fr/passer-laction/les-durees-de-conservation-des-donnees</Paragraph>

            <SubTitle>4. Durée de conservation des données</SubTitle>
            <Paragraph>Web Boost s'engage à respecter les durées de conservation suivantes, conformément au RGPD et aux recommandations de la CNIL :</Paragraph>

            <Paragraph><Strong>4.1. Données des utilisateurs (nom, email, téléphone) :</Strong></Paragraph>
            <List>
                <ListItem>Finalité : Gestion des informations utilisateurs.</ListItem>
                <ListItem>Durée : Pendant l'utilisation du compte, suppression après 2 ans d'inactivité.</ListItem>
            </List>
            <Paragraph>Plus d’informations : https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000045538574</Paragraph>

            <Paragraph><Strong>4.2. Données de facturation :</Strong></Paragraph>
            <List>
                <ListItem>Finalité : Respect des obligations comptables et fiscales.</ListItem>
                <ListItem>Durée : 10 ans.</ListItem>
            </List>
            <Paragraph>Plus d’informations : https://www.cnil.fr/fr/passer-laction/les-durees-de-conservation-des-donnees</Paragraph>

            <Paragraph><Strong>4.3. Données de contact (formulaire, support client, devis, etc.) :</Strong></Paragraph>
            <List>
                <ListItem>Finalité : Suivi des demandes et assistance</ListItem>
                <ListItem>Durée : 3 ans à compter du dernier contact.</ListItem>
            </List>
            <Paragraph>Plus d’informations : https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000045538574</Paragraph>

            <Paragraph><Strong>4.4. Données collectées pour la prospection commerciale :</Strong></Paragraph>
            <List>
                <ListItem><Strong>Finalité : </Strong>Envoi d'emails, SMS, promotions.</ListItem>
                <ListItem>
                    <Strong>Durée de conservation :</Strong>
                    <Paragraph><Strong>Pour les clients : </Strong>Les données peuvent être conservées pendant la durée de la relation commerciale, puis pour une durée de <Strong>trois ans</Strong> à compter de la fin de cette relation.</Paragraph>
                    <Paragraph><Strong>Pour les prospects non clients : </Strong>Les données peuvent être conservées pendant un délai de <Strong>trois ans</Strong> à compter de leur collecte ou du dernier contact émanant du prospect.</Paragraph>
                    <Paragraph>Au terme de ce délai, Web Boost pourra reprendre contact avec la personne concernée afin de savoir si elle souhaite continuer à recevoir des sollicitations commerciales. En l'absence de réponse positive et explicite, les données seront supprimées ou archivées conformément aux dispositions en vigueur, selon la délibération n° 2021-131 du 23 septembre 2021.</Paragraph>
                </ListItem>
            </List>

            <SubTitle>5. Partage des données</SubTitle>
            <Paragraph>Nous ne partageons vos données personnelles avec des tiers que dans les cas suivants :</Paragraph>
            <List>
                <ListItem>Avec votre consentement explicite.</ListItem>
                <ListItem>Avec des prestataires de services nécessaires à la fourniture de nos services.</ListItem>
                <ListItem>Pour nous conformer à la loi.</ListItem>
            </List>
            
            <Paragraph>Emplacement de stockage et transfert des données</Paragraph>
            <List>
                <ListItem>Données de contact du site internet : Hébergées par LWS France, serveurs situés en France.</ListItem>
                <ListItem>Données de contact : Les données de contact échangées via WhatsApp sont stockées sur les serveurs de WhatsApp, qui peuvent être situés en dehors de l'Union européenne.</ListItem>
                <ListItem>Données clients : Partiellement stockées sur Google Drive, avec possibilité de choisir la région de stockage en Europe.</ListItem>
            </List>
            <Paragraph>Des transferts de données hors UE peuvent se produire. Web Boost veille à ce que des garanties appropriées soient mises en place, conformément au RGPD.</Paragraph>
            <Paragraph>Dans le cadre de l'utilisation de nos services, Web Boost peut être amené à utiliser la plateforme Discord pour faciliter la communication avec ses utilisateurs. À ce titre, certaines de vos données personnelles peuvent être traitées via Discord.</Paragraph>
            
            <Paragraph><Strong>5.1.  Information des utilisateurs de la plateforme Discord sur la collecte et l'utilisation des données personnelles</Strong></Paragraph>
            <Paragraph>Discord est une plateforme de communication en ligne qui permet des échanges en temps réel via des messages texte, audio ou vidéo. Lorsque vous interagissez avec Web Boost sur Discord, les données suivantes peuvent être collectées :</Paragraph>
            <List>
                <ListItem><Strong>Identifiants Discord :</Strong> nom d'utilisateur, identifiant unique.</ListItem>
                <ListItem><Strong>Contenu des communications :</Strong> messages envoyés, fichiers partagés, images, vidéos, urls, etc.</ListItem>
                <ListItem><Strong>Données techniques :</Strong> adresse IP, informations sur l'appareil et le navigateur utilisés.</ListItem>
            </List>
            
            <Paragraph><Strong>Finalités du traitement</Strong></Paragraph>
            <Paragraph>Les données collectées via Discord sont utilisées pour :</Paragraph>
            <List>
                <ListItem>Faciliter la communication et le support client.</ListItem>
                <ListItem>Gérer la communauté et interactions au sein du serveur Discord de Web Boost.</ListItem>
                <ListItem>Assurer la sécurité et le bon fonctionnement des services proposés sur Discord.</ListItem>
            </List>
            
            <Paragraph><Strong>Partage des données</Strong></Paragraph>
            <Paragraph>Vos données personnelles ne sont partagées avec des tiers que dans les cas suivants :</Paragraph>
            <List>
                <ListItem>Avec votre consentement explicite.</ListItem>
                <ListItem>Avec des prestataires de services nécessaires à la fourniture de nos services, y compris Discord.</ListItem>
                <ListItem>Pour nous conformer à la loi.</ListItem>
            </List>
            
            <Paragraph><Strong>Emplacement de stockage et transfert des données</Strong></Paragraph>
            <Paragraph>Discord est une entreprise américaine, et les données traitées via leur plateforme peuvent être transférées et stockées en dehors de l'Union européenne. Web Boost veille à ce que des garanties appropriées soient mises en place, conformément aux articles 44 à 50 du RGPD, pour assurer un niveau de protection adéquat des données transférées.</Paragraph>
            
            <Paragraph><Strong>Vos droits</Strong></Paragraph>
            <Paragraph>Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :</Paragraph>
            <List>
                <ListItem><Strong>Droit d'accès :</Strong> obtenir la confirmation que vos données sont traitées et accéder à ces données.</ListItem>
                <ListItem><Strong>Droit de rectification :</Strong> demander la correction de données inexactes ou incomplètes.</ListItem>
                <ListItem><Strong>Droit à l'effacement :</Strong> demander la suppression de vos données dans certaines circonstances.</ListItem>
                <ListItem><Strong>Droit à la limitation du traitement :</Strong> demander la suspension du traitement de vos données dans certaines conditions.</ListItem>
                <ListItem><Strong>Droit d'opposition :</Strong> vous opposer au traitement de vos données pour des motifs légitimes.</ListItem>
                <ListItem><Strong>Droit à la portabilité :</Strong> recevoir vos données dans un format structuré et couramment utilisé, et les transmettre à un autre responsable de traitement.</ListItem>
            </List>
            <Paragraph>Pour exercer ces droits, veuillez contacter notre Délégué à la Protection des Données (DPO), Julien Maingault, à l'adresse suivante : contact@web-boost.fr.</Paragraph>
            <Paragraph>Veuillez noter que Discord dispose de sa propre politique de confidentialité, que nous vous encourageons à consulter pour comprendre comment vos données sont traitées par leur service.</Paragraph>
            
            <Paragraph><Strong>5.2. Information des utilisateurs WhatsApp sur la collecte et l'utilisation des données personnelles</Strong></Paragraph>
            <Paragraph>Conformément à l'article 13 du RGPD,</Paragraph>
            <List>
                <ListItem>Les données sont conservées selon l’article 4 de la politique de confidentialité.</ListItem>
                <ListItem>Votre droit de retirer votre consentement à tout moment.</ListItem>
                <ListItem>Votre droit d'introduire une réclamation auprès d'une autorité de contrôle.</ListItem>
                <ListItem>Nous ne transférons pas vos données personnelles vers un pays tierce ou une organisation internationale.</ListItem>
            </List>

            <Paragraph><Strong>Collecte et utilisation des données personnelles via WhatsApp</Strong></Paragraph>
            <Paragraph>Dans le cadre de la gestion de projet, Web-boost utilise l'application de messagerie WhatsApp pour faciliter la communication entre les parties prenantes. Les données personnelles collectées et échangées via cette plateforme incluent, sans s'y limiter, les noms, numéros de téléphone, adresses e-mail, et toute autre information pertinente au projet. Ces données sont utilisées exclusivement pour les besoins de la communication et de la coordination du projet.</Paragraph>

            <Paragraph><Strong>Consentement explicite des parties prenantes</Strong></Paragraph>
            <Paragraph>Conformément aux articles 6, 4(11) et 7 du RGPD, le traitement des données personnelles via WhatsApp nécessite le consentement libre, spécifique, éclairé et univoque des personnes concernées. Avant d'ajouter une partie prenante à un groupe WhatsApp ou de partager ses informations sur cette plateforme, Web-boost s'engage à obtenir son consentement explicite. Ce consentement sera recueilli par le biais d'un accord de non-divulgation (NDA) signé ou d'une approbation formelle obtenue via un message privé préalable. Les parties prenantes sont informées qu'elles peuvent retirer leur consentement à tout moment, sans justification, en contactant notre Délégué à la Protection des Données (DPO), Julien Maingault, à l'adresse suivante : contact@web-boost.fr.</Paragraph>
            
            <Paragraph><Strong>Mesures de protection des données</Strong></Paragraph>
            <Paragraph>Web-boost met en œuvre des mesures techniques et organisationnelles appropriées pour assurer la sécurité des données personnelles échangées via WhatsApp, conformément à l'article 32 du RGPD. Toutefois, il est rappelé aux parties prenantes que WhatsApp est une plateforme tierce, et que Web-boost ne peut garantir la sécurité absolue des données sur cette application. Par conséquent, il est recommandé de ne pas partager de données sensibles ou confidentielles via WhatsApp. Pour de telles informations, Web-boost propose des canaux de communication sécurisés alternatifs, consistant à envoyer un dossier compressé protégé par un mot de passe, suivi de la transmission du mot de passe via un message privé.</Paragraph>
            
            <Paragraph><Strong>Droits des personnes concernées</Strong></Paragraph>
            <Paragraph>Conformément aux articles 15 à 22 du RGPD, les parties prenantes disposent de droits sur leurs données personnelles, notamment le droit d'accès, de rectification, d'effacement, de limitation du traitement, d'opposition et de portabilité. Pour exercer ces droits, les parties prenantes peuvent contacter notre Délégué à la Protection des Données (DPO), Julien Maingault, à l'adresse suivante : contact@web-boost.fr.</Paragraph>
            
            <SubTitle>6. Sécurité des données</SubTitle>
            <Paragraph>
                Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles, notamment :
            </Paragraph>
            <List>
                <ListItem>Cryptage des données lors de la transmission et du stockage.</ListItem>
                <ListItem>Utilisation de pare-feu.</ListItem>
                <ListItem>Contrôles d'accès limitant l'accès aux données personnelles.</ListItem>
            </List>

            <SubTitle>7. Droits des utilisateurs</SubTitle>
            <Paragraph>Conformément au RGPD, vous disposez des droits suivants :</Paragraph>
            <List>
                <ListItem>Droit d'accès.</ListItem>
                <ListItem>Droit de rectification.</ListItem>
                <ListItem>Droit à l'effacement.</ListItem>
                <ListItem>Droit de limitation du traitement.</ListItem>
                <ListItem>Droit d'opposition au traitement.</ListItem>
                <ListItem>Droit à la portabilité des données.</ListItem>
            </List>
            <Paragraph>Pour exercer ces droits ou retirer votre consentement, contactez-nous à contact@web-boost.fr. Nous traiterons votre demande dans un délai maximal d'un mois.</Paragraph>

            <SubTitle>8. Portabilité des données</SubTitle>
            <Paragraph>Web Boost s'engage à faciliter l'exercice de votre droit à la portabilité des données, conformément à l'article 20 du RGPD. Voici les détails concernant la portabilité des données :</Paragraph>
            <List>
                <ListItem>Formats de transfert : Nous transférons les données dans des formats couramment utilisés et lisibles par machine, notamment CSV, JSON, ou XML.</ListItem>
                <ListItem>Sécurité du transfert : Nous garantissons le transfert de vos données personnelles par des voies sécurisées pour protéger vos informations pendant le processus de portabilité.</ListItem>
                <ListItem>Données concernées : La portabilité s'applique aux données personnelles que vous nous avez fournies directement, telles que les informations de contact et les données de formulaire.</ListItem>
                <ListItem>Procédure de demande : Pour exercer votre droit à la portabilité des données, veuillez contacter notre Délégué à la Protection des Données, Julien Maingault, à l'adresse contact@web-boost.fr .</ListItem>
                <ListItem>Délai de traitement : Conformément à l'article 12(3) du RGPD, nous traiterons votre demande de portabilité dans un délai maximal d'un mois à compter de sa réception.</ListItem>
            </List>
            
            <SubTitle>9. Délégué à la Protection des Données (DPO)</SubTitle>
            <Paragraph>Web Boost a désigné Julien Maingault comme Délégué à la Protection des Données. Pour toute question relative à vos données personnelles, contactez-le à contact@web-boost.fr .</Paragraph>
            <Paragraph><Strong>Transfert de données hors de l'Union européenne :</Strong></Paragraph>
            <Paragraph>Web Boost s'efforce de stocker les données personnelles de ses utilisateurs au sein de l'UE. Toutefois, en raison de l'utilisation de services tels que Google Drive, des transferts de données en dehors de l'UE peuvent se produire. Dans ces cas, Web Boost veille à ce que des garanties appropriées soient en place, conformément aux articles 44 à 50 du RGPD, pour assurer un niveau de protection adéquat des données transférées.</Paragraph>
            <Paragraph><Strong>Droits des utilisateurs :</Strong></Paragraph>
            <Paragraph>Les données collectées de l'utilisateur ne sont pas à caractère sensible (origine raciale ou ethnique, opinions politiques, convictions religieuses ou philosophiques, orientation sexuelle, appartenance syndicale, données génétiques).</Paragraph>
            <Paragraph>Conformément à l'article 7(3) du <Strong>Règlement Général sur la Protection des Données (RGPD)</Strong>, vous avez le droit de retirer votre consentement au traitement de vos données personnelles à tout moment. Pour exercer ce droit, veuillez envoyer votre demande à l'adresse suivante : contact@web-boost.fr.</Paragraph>
            <Paragraph>Conformément à l'article 12(3) du RGPD, Web Boost s'engage à traiter votre demande de retrait de consentement dans un délai maximal d'un mois à compter de sa réception. Si votre demande est complexe ou nécessite un examen approfondi, ce délai peut être prolongé de deux mois supplémentaires. Dans ce cas, vous serez informé de cette prolongation et des raisons du report dans un délai d'un mois à compter de la réception de votre demande.</Paragraph>
            <Paragraph>Veuillez noter que le retrait de votre consentement n'affecte pas la licéité du traitement effectué avant ce retrait. Après le traitement de votre demande, vos données personnelles seront supprimées ou anonymisées, sauf si une obligation légale impose leur conservation.</Paragraph>
            <Paragraph>Cette politique de confidentialité est intégrée dans les Conditions Générales de Vente (CGV) de Web Boost pour assurer une transparence totale concernant la gestion des données personnelles des utilisateurs.</Paragraph>
            <Footer />
        </Container>
    )
}

export default PrivacyPolicyPage