import { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { GlobalContext } from "../utils/global"
import MobileNavigation from "../components/MobileNavigation"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import planet2 from '../assets/blueRingPlanet.svg'
import planet3 from "../assets/planetEvo3.svg"
import planet1 from "../assets/planetBYR3R.svg"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const Container = styled.div.attrs(props => ({
    $height: props.$height
}))`
    height: ${props => `calc(100vh - ${ props.$height }px)`};
    display: flex;
    flex-direction: column;
    position: relative;
`

const NavContainer = styled.div`

`

const Wrapper = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
`

const Title = styled.h2`
    font-size: 12rem;
    color: #130633;
    text-align: center;
    padding: 0 2rem;
`

const Text = styled.span`
    font-size: 4rem;
    color: #130633;
    text-align: center;
    padding: 0 2rem;
`

const ImageContainer = styled.div`
    position: absolute;

    &.one {
        top: 12%;
        left: 78%;
        width: 150px;
        height: 150px;
    }
    &.two {
        top: 60%;
        left: 11%;
        width: 110px;
        height: 110px;
    }
    &.three {
        top: 5%;
        left: 38%;
        width: 130px;
        height: 130px;
    }
    @media only screen and (max-width : 767px) {
        &.one {
            width: 100px;
            height: 100px;
        }
        &.two {
            top: 40%;
            left: 11%;
            width: 90px;
            height: 90px;
        }
        &.three {
            top: 11%;
            left: 4%;
            width: 80px;
            height: 80px;
        }
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
`

function ErrorPage () {
    const { device } = useContext(GlobalContext)
    const navRef = useRef()
    const [containerHeight, setContainerHeight ] = useState(0)

    useGSAP(() => {

        const tl = gsap.timeline()
        tl.from('.planet-anim', {
            y: Math.random() * (10 - 5) + 5,
            ease: "power1.out",
            duration: 1,
            stagger: {
                amount: 2,
                yoyo: true,
                repeat: -1,
                each: .2
            }
        })
    })

    useEffect(() => {
        if(navRef.current) {
            console.log(navRef.current.clientHeight)
            setContainerHeight(navRef.current.clientHeight)
        }
    }, [navRef.current])

    return (
        <>
            <NavContainer ref={ navRef }>
                {device === 'mobile' && <MobileNavigation />}
                {(device === 'tablet' || device === 'desktop') && <Navigation />}
            </NavContainer>
            
            <Container $height={ containerHeight }>
                <ImageContainer className="two planet-anim">
                    <Image src={ planet2 } alt="image de planète" />
                </ImageContainer>

                <ImageContainer className="three planet-anim">
                    <Image src={ planet3 } alt="image de planète" />
                </ImageContainer>

                <ImageContainer className="one planet-anim">
                    <Image src={ planet1 } alt="image de planète" />
                </ImageContainer>

                <Wrapper>
                    <Title>404</Title>
                    <Text>Oups ! La page est introuvable.</Text>
                </Wrapper>

                <Footer />
            </Container>
        </>
        
    )
}

export default ErrorPage