import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import planet from "../../assets/planet.svg"
import orangePlanet from "../../assets/orangePlanet.svg"
import planet2 from "../../assets/planetEvo3.svg"
import planet3 from "../../assets/planetBY.svg"
import planet4 from "../../assets/planetBYR.svg"
import planet5 from "../../assets/planetBYR3R.svg"
import planet6 from "../../assets/planetR.svg"
import planet7 from "../../assets/planetEvo2.svg"
import planet8 from '../../assets/blueRingPlanet.svg'
import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const Section = styled.section`
    background-color: #ffffff;
    position: relative;
    z-index: 10;
    padding-top: 5rem;
`

const Planetarium = styled.div`
    position: relative;
    width: 100%;
    height: 600px;

    @media only screen and (max-width : 767px) {
        height: 500px;
    }
`

const PlanetContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &.python {
        top: 130px;
        right: 26%;
    }
    &.css {
        top: 286px;
        right: 15%;
    }
    &.react {
        top: 433px;
        right: 25%;
    }
    &.javascript {
        top: 456px;
        right: 57%;
    }
    &.node {
        top: 297px;
        right: 80%;
    }
    &.html {
        top: 41px;
        right: 65%;
    }
    &.seo {
        top: 13px;
        right: 34%;
    }
    &.rgpd {
        top: 227px;
        right: 61%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.python {
            top: 130px;
            right: 3%;
        }
        &.css {
            top: 286px;
            right: 15%;
        }
        &.react {
            top: 410px;
            right: 3%;
        }
        &.javascript {
            top: 456px;
            right: 43%;
        }
        &.node {
            top: 338px;
            right: 70%;
        }
        &.html {
            top: 80px;
            right: 70%;
        }
        &.seo {
            top: 66px;
            right: 31%;
        }
        &.rgpd {
            top: 227px;
            right: 65%;
        }
    }

    @media only screen and (max-width : 500px) {
        flex-direction: row-reverse;

        &.python {
            top: 91px;
            right: 26%;
        }
        &.css {
            top: 150px;
            right: 1%;
        }
        &.react {
            top: 338px;
            right: 10%;
        }
        &.javascript {
            top: 420px;
            right: 26%;
        }
        &.node {
            top: 342px;
            right: 43%;
        }
        &.html {
            top: 117px;
            right: 44%;
        }
        &.seo {
            top: 0;
            right: 12%;
        }
        &.rgpd {
            top: 273px;
            right: 56%;
        }
    }
    @media only screen and (min-width: 501px) and (max-width: 767px) {
        &.python {
            top: 91px;
            right: 33%;
        }
        &.css {
            top: 150px;
            right: 10%;
        }
        &.react {
            top: 337px;
            right: 18%;
        }
        &.javascript {
            top: 420px;
            right: 26%;
        }
        &.node {
            top: 342px;
            right: 43%;
        }
        &.seo {
            top: 0;
            right: 16%;
        }
        &.html {
            top: 117px;
            right: 55%;
        }
        &.rgpd {
            top: 273px;
            right: 63%;
        }
    }
`

const ImageContainer = styled.div`
    width: fit-content;

    &.technoPlanet {
        height: fit-content;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }

    &.python {
        width: 100px;
    }
    &.css {
        width: 130px;
    }
    &.react {
        width: 80px;
    }
    &.javascript {
        width: 120px;
    }
    &.node {
        width: 90px;
    }
    &.html {
        width: 100px;
    }
    &.seo {
        width: 80px;
    }
    &.rgpd {
        width: 60px;
    }

    @media only screen and (max-width : 767px) {
        &.technoPlanet {
            width: 130px;
        }
        &.python {
            width: 45px;
        }
        &.css {
            width: 75px;
        }
        &.javascript {
            width: 90px;
        }
        &.node {
            width: 50px;
        }
        &.html {
            width: 70px;
        }
        &.seo {
            width: 80px;
        }
        &.react {
            width: 65px;
        }
    }
`

const Image = styled.img`
    width: 100%;
`

const Label = styled.span`
    font-size: 1.5rem;
    font-weight: 800;
    padding: 0 1rem;

    @media only screen and (max-width : 767px) {
        font-size: 1.1rem;
    }
`

function TechnologiesSection() {
    useGSAP(() => {
        // gsap.from('.technologies-title', {
        //     y: 130,
        //     ease: "power4.out",
        //     duration: 1.8,
        //     scrollTrigger: {
        //         trigger: '.technologies-title',
        //         start: "top 75%"
        //     }
        // })

        const tl = gsap.timeline()
        tl.from('.planet-anim', {
            y: Math.random() * (10 - 5) + 5,
            ease: "power1.out",
            duration: 1,
            stagger: {
                amount: 2,
                yoyo: true,
                repeat: -1,
                each: .2
            }
        })
    })

    return (
        <Section>
            <SectionTitle classContainer={"not-margin"} label={i18next.t("techno-section-title")} id="technologies-title" />
            <Planetarium>
                <ImageContainer className="technoPlanet">
                    <Image src={planet} alt="image d'une planète" />
                </ImageContainer>

                <PlanetContainer className="html">
                    <ImageContainer className="html planet-anim">
                        <Image src={orangePlanet} alt="image d'une planète" />
                    </ImageContainer>
                    <Label>HTML</Label>
                </PlanetContainer>

                <PlanetContainer className="css">
                    <ImageContainer className="css planet-anim">
                        <Image src={planet2} alt="image d'une planète" />
                    </ImageContainer>
                    <Label>CSS</Label>
                </PlanetContainer>

                {/* <PlanetContainer className="react">
                    <ImageContainer className="react planet-anim">
                        <Image src={planet3} alt="image d'une planète" />
                    </ImageContainer>
                    <Label>React</Label>
                </PlanetContainer> */}

                <PlanetContainer className="react">
                    <ImageContainer className="react planet-anim">
                        <Image src={planet3} alt="image d'une planète" />
                    </ImageContainer>
                    <Label>{ i18next.t("planet-marketing") }</Label>
                </PlanetContainer>

                <PlanetContainer className="seo">
                    <ImageContainer className="seo planet-anim">
                        <Image src={planet4} alt="image d'une planète" />
                    </ImageContainer>
                    <Label>SEO</Label>
                </PlanetContainer>

                {/* <PlanetContainer className="node">
                    <Label>Node JS</Label>
                    <ImageContainer className="node planet-anim">
                        <Image src={planet6} alt="image d'une planète" />
                    </ImageContainer>
                </PlanetContainer>

                <PlanetContainer className="python">
                    <Label>Python</Label>
                    <ImageContainer className="python planet-anim">
                        <Image src={planet5} alt="image d'une planète" />
                    </ImageContainer>
                </PlanetContainer> */}

                <PlanetContainer className="javascript">
                    <Label>{ i18next.t("planet-method") }</Label>
                    <ImageContainer className="javascript planet-anim">
                        <Image src={planet8} alt="image d'une planète" />
                    </ImageContainer>
                </PlanetContainer>

                <PlanetContainer className="rgpd">
                    <Label>RGPD</Label>
                    <ImageContainer className="rgpd planet-anim">
                        <Image src={planet7} alt="image d'une planète" />
                    </ImageContainer>
                </PlanetContainer>
            </Planetarium>
        </Section>
    )
}

export default TechnologiesSection